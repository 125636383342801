@import 'overrides/overrides';
@import '../../aveda_base/scss/aveda-base-mobile';
@import '../../core_base/scss/core_base_mobile';
/* Styles added for Email sighup, Contact us & Unsubscribe page */

#email-signup,
#contact-us {
  margin: 15px 10px;
  .email-signup__header h1,
  .contact-us__header h1 {
    text-align: center;
  }
}

.form-type-textfield input {
  display: block;
  height: 36px;
  line-height: 36px;
  padding: 0 10px 0 10px;
  width: 100%;
}

.cs-page__content {
  margin: 10px 0;
  .form-type-textfield,
  .form-textarea-wrapper {
    margin-bottom: 10px;
  }
}

.form-item-form-Email-Address {
  margin-bottom: 10px;
}

.page-utilities__signup {
  input[type='email'] {
    width: 100%;
    margin-bottom: 10px;
  }
  input[type='submit'],
  input[type='button'],
  button {
    width: 100%;
  }
}

.email-signup__content {
  .form-error.label-error {
    height: 20px;
    overflow: hidden;
  }
}

.contact-us__content {
  .form-error.label-error {
    height: 20px;
    overflow: hidden;
  }
  .grippie {
    display: none;
  }
}

#contact-us-thanks-page {
  .contact-us-thanks-header__title {
    margin-top: 5%;
  }
  .float-left.submit-button {
    margin-bottom: 5%;
  }
}

.form-item-form-EMAIL-PROMOTIONS-1 span.form-required {
  display: none;
}

.form-item-form-AGREE-POLICY span.form-required {
  display: none;
}

.site-email-signup__success--welcome-15 {
  .form-text offers-email-signup-form__email {
    float: left;
    width: 246px;
  }
  .form-submit {
    width: 246px;
    float: left;
    margin-top: 15px;
    background: transparent none repeat scroll 0 0;
    color: #fff;
    border: 1px solid #fff;
  }
}

.la-header__menu-trigger {
  top: 9px;
}

.site-header__utility-right {
  padding-right: 15px !important;
}

div#contact-us-thanks-page {
  margin: 25px;
}

$break-small: 570px;

.site-header__promo .sign-up-link {
  @media screen and (max-width: $break-small) {
    display: none;
  }
}
